import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    userService as service,
    companyService,
    moneymovementsService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import TabRental from './TabRental'
import TabShare from './TabShare'
import TabMoney from './TabMoney'
import TabDocument from './TabDocument'
import { Box, Button, Modal, TextField } from '@mui/material'
import Form from '../../form/Form'
import TabBono from './TabBonos'
import CrudFormStateTabs from '../../crud/CrudFormStateTabs'

const initElement = {
    email: '',
    pass: '',
    name: '',
    lastname: '',
    isClient: true,
    rol: { code: 'CLIENT', name: 'CLIENT' },
    enable: true,
    picture: null,
    acceptnews: false,
    acceptTerms: null,
    acceptPoli: null,
    nif: null,
    alias: null,
    street: null,
    city: null,
    state: null,
    postalcode: null,
    dni_front: null,
    dni_back: null,
    license_front: null,
    license_back: null,
    credit_number: null,
    credit_code: null,
    credit_month: null,
    credit_year: null,
    avatar: null,
    dni_day: null,
    dni_month: null,
    dni_year: null,
    license_day: null,
    license_month: null,
    license_year: null,
    bank_account: null,
    redsys_token: null,
    stripe_id: null,
    money: null,
    recarge: false,
    moneyRecarge: null,
    company: { id: null, name: null },
    validate: false,
}

type FormAdministratorParms = {
    id: string
}

export default function FormUsers() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    let { id } = useParams<FormAdministratorParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    var stateForm = {
        openMoney: false,
        openValidate: false,
        openReject: false,
        reload: false,
        motive: '',
        money: {
            user: { id: id },
            concept: null,
            money: null,
        },
    }

    const estructureform = [
        {
            type: 'input',
            name: 'email',
            readonly: idRow > 0 ? true : false,
            col: 4,
        },
        {
            type: 'input',
            name: 'phone',
            col: 4,
        },
        {
            type: 'input',
            name: 'pass',
            col: 4,
            inputtype: 'password',
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'lastname',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company.id',
            col: 4,
            service: companyService.combo,
        },
        {
            type: 'image',
            name: 'picture',
            col: 2,
        },
        {
            type: 'checkbox',
            name: 'acceptnews',
            readonly: true,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'validateState',
            options: [
                { key: 'NONE', name: 'Documentación no enviada' },
                { key: 'PENDING', name: 'Pendiente de validar' },
                { key: 'VALIDATE', name: 'Validada' },
                { key: 'REJECT', name: 'Rechazada' },
            ],
            readonly: true,
            col: 4,
        },
        {
            type: 'input',
            name: 'acceptTerms',
            readonly: true,
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'acceptPoli',
            readonly: true,
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'isMoroso',
            col: 4,
        },
        {
            type: 'title',
            name: 'titledir',
            col: 12,
        },
        {
            type: 'input',
            name: 'street',
            col: 5,
        },
        {
            type: 'input',
            name: 'number',
            col: 1,
        },
        {
            type: 'input',
            name: 'floor',
            col: 1,
        },
        {
            type: 'input',
            name: 'door',
            col: 1,
        },
        {
            type: 'input',
            name: 'city',
            col: 4,
        },
        {
            type: 'input',
            name: 'postalcode',
            col: 4,
        },
        {
            type: 'input',
            name: 'state',
            col: 4,
        },
        {
            type: 'title',
            name: 'titledni',
            col: 12,
        },
        {
            type: 'image',
            name: 'dni_front',
            col: 2,
        },
        {
            type: 'image',
            name: 'dni_back',
            col: 2,
        },
        {
            type: 'input',
            name: 'nif',
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },

        {
            type: 'input',
            name: 'birth_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'birth_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'birth_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'checkbox',
            name: 'minor25',
            col: 4,
        },
        {
            type: 'title',
            name: 'titlecond',
            col: 12,
        },
        {
            type: 'image',
            name: 'license_front',
            col: 2,
        },
        {
            type: 'image',
            name: 'license_back',
            col: 2,
        },
        {
            type: 'title',
            name: 'space',
            col: 8,
        },
        {
            type: 'input',
            name: 'license_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'license_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'license_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'licenseInit_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'licenseInit_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'licenseInit_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'checkbox',
            name: 'minor3',
            col: 4,
        },
        {
            type: 'title',
            name: 'titlepay',
            col: 12,
        },
        {
            type: 'input',
            name: 'bank_account',
            col: 8,
        },
        {
            type: 'input',
            name: 'redsys_token',
            readonly: true,
            col: 4,
        },
        {
            type: 'input',
            name: 'stripe_id',
            readonly: false,
            col: 4,
        },
        {
            type: 'title',
            name: 'titlemoney',
            col: 12,
        },
        {
            type: 'input',
            name: 'money',
            readonly: false,
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'recarge',
            readonly: false,
            col: 4,
        },
        {
            type: 'input',
            name: 'moneyRecarge',
            readonly: false,
            col: 4,
        },
    ]

    const createEstructureMoney = [
        {
            type: 'input',
            name: 'concept',
            col: 6,
        },
        {
            type: 'input',
            name: 'money',
            inputtype: 'number',
            col: 6,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }

        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('administrator.error.name')
        }
        if (!object['email']) {
            return t('administrator.error.email')
        }
        if (
            !object['rol'] ||
            !object['rol']['code'] ||
            !object['rol']['code'] == null
        ) {
            return t('administrator.error.rol')
        }

        return null
    }

    const tabRental = (id) => {
        return <TabRental idclient={id} />
    }

    const tabShare = (id) => {
        return <TabShare idclient={id} />
    }

    const tabMoney = (id) => {
        return <TabMoney idclient={id} />
    }

    const tabDocs = (id) => {
        return <TabDocument idclient={id} />
    }

    const tabBono = (id) => {
        return <TabBono idclient={id} />
    }

    const saveMoney = (object) => {
        if (object['concept'] && object['concept'] !== '' && object['money']) {
            moneymovementsService.create(object, (data, error) => {
                if (data && data.data) {
                    //handleCloseMoney()
                    window.location.reload()
                }
            })
        }
    }

    const validateUser = () => {
        service.validate(id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            }
        })
    }

    const rejectUser = (state) => {
        service.reject(id, { motive: state.motive }, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            }
        })
    }

    const moreActions = (getObject, state, saveState) => {
        var isGenerate = id && getObject && getObject()
        var isValid =
            id &&
            getObject &&
            getObject() &&
            getObject().validateState == 'VALIDATE'
        var isReject =
            id &&
            getObject &&
            getObject() &&
            getObject().validateState == 'REJECT'
        return (
            <>
                {isGenerate && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() =>
                            setStateInParent('openMoney', true, saveState)
                        }
                    >
                        {' '}
                        Añadir movimiento monedero
                    </Button>
                )}
                {isGenerate && !isValid && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() =>
                            setStateInParent('openValidate', true, saveState)
                        }
                    >
                        {' '}
                        Validar
                    </Button>
                )}
                {isGenerate && !isReject && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() =>
                            setStateInParent('openReject', true, saveState)
                        }
                    >
                        {' '}
                        Rechazar
                    </Button>
                )}
            </>
        )
    }

    const setStateInParent = (name, val, call) => {
        debugger
        if (val) {
            call(name, val)
        } else {
            call(name, val)
        }
    }

    return (
        <>
            <CrudFormStateTabs
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'users'}
                urledit={'/users/edit/'}
                urlCancel={'/users'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                reload={stateForm.reload}
                tabs={[
                    { title: 'users.tab.rent', method: tabRental },
                    { title: 'users.tab.share', method: tabShare },
                    { title: 'users.tab.money', method: tabMoney },
                    { title: 'users.tab.bono', method: tabBono },
                    { title: 'users.tab.docs', method: tabDocs },
                ]}
                moreActions={moreActions}
                stateform={stateForm}
                haveInside={(state, saveState) => {
                    return (
                        <>
                            <Modal
                                open={state.openMoney}
                                onClose={() =>
                                    setStateInParent(
                                        'openMoney',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Quieres añadir un nuevo movimiento?
                                    </div>
                                    <div>
                                        <Form
                                            t={t}
                                            i18n={'money'}
                                            objectform={state.money}
                                            fields={createEstructureMoney}
                                            searchFilter={saveMoney}
                                            cleanFilter={() =>
                                                setStateInParent(
                                                    'openMoney',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        />
                                    </div>
                                </Box>
                            </Modal>
                            <Modal
                                open={state.openValidate}
                                onClose={() =>
                                    setStateInParent(
                                        'openValidate',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Estas seguro que deseas validar el
                                        usuario?
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openValidate',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            No
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={validateUser}
                                        >
                                            {' '}
                                            Sí{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                            <Modal
                                open={state.openReject}
                                onClose={() =>
                                    setStateInParent(
                                        'openReject',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Estas seguro que deseas rechazar el
                                        usuario?
                                    </div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <TextField
                                            variant="standard"
                                            sx={{ width: '100%' }}
                                            id={'motive'}
                                            name={'motive'}
                                            label={t('user.field.motive')}
                                            value={state.motive}
                                            onChange={(evt) => {
                                                setStateInParent(
                                                    'motive',
                                                    evt.target.value,
                                                    saveState
                                                )
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openReject',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            No
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                !state.motive ||
                                                state.motive == ''
                                            }
                                            onClick={() => rejectUser(state)}
                                        >
                                            {' '}
                                            Sí{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </>
                    )
                }}
            />
        </>
    )
}
